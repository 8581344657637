<template>
  <div class="lawsand-page">
    <div class="lawand-content">
      <div class="lawand-header-upload">
        <el-button type="primary" icon="el-icon-top" @click="add">上传文件</el-button>
      </div>
      <div class="content" style="height: 660px">
        <el-table
          :data="tableData"
          style="width: 100%"
          max-height="660"
          :header-cell-style="{
              color: '#333333',
              backgroundColor: '#FBFBFD',
            }"
          row-key="id"
          border
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          lazy>
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="62">
          </el-table-column>
          <el-table-column
            prop="FileName"
            label="文件名"
            align="center"
            min-width="200">
            <template slot-scope="scope">
              <span>{{scope.row.FileName}}</span><span>.{{scope.row.Suffix}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="UserName"
            label="上传人"
            align="center"
            width="180">
          </el-table-column>
          <el-table-column
            prop="CreateDate"
            align="center"
            label="上传时间"
            width="240"
          >
          </el-table-column>
          <el-table-column label="操作" align="center" width="320">
            <template slot-scope="scope">
<!--              <el-link-->
<!--                style="font-size:18px;color: #3978E7;margin-right: 11px"-->
<!--                type="text"-->
<!--                :underline="false"-->
<!--                @click="edit(scope.row)"-->
<!--               ><i class="el-icon-edit-outline"></i>编辑</el-link>-->
              <el-link
                style="font-size:18px;color: #3978E7;margin-right: 11px"
                type="text"
                :underline="false"
                @click="deletefile(scope.row)"
               ><i class="el-icon-delete"></i>删除</el-link>
              <el-link
                style="font-size:18px;color: #3978E7"
                type="text"
                :underline="false"
                @click="pre(scope.row.Id)"
              ><img src="../../../assets/image/notice/query.png" alt="" style="width: 16px;height: 16px;vertical-align: -3px">查看</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="eachpage"
          layout="total,prev, pager, next, jumper"
          :total="PageCount">
        </el-pagination>
      </div>
    </div>
    <div class="lawsand-upload">
      <el-dialog
        :visible.sync="dialogVisible"
        v-dialogDrag
       >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">上传文件</span>
        </div>
        <el-form ref="form" label-width="80px">
<!--          <el-form-item label="文件名称:">-->
<!--            <el-input  v-model="fileName"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="文件说明:">
            <el-input type="textarea" v-model="Remarks"></el-input>
          </el-form-item>
          <el-form-item label="文件上传:">
            <el-upload
              class="upload-demo"
              ref="newupload"
              accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"
              multiple
              action="#"
              :file-list="fileList"
              :auto-upload="false"
              :on-change = "change"
              :on-remove="handleRemove"
              :before-upload="beforeUpload"
            >
              <el-button size="medium" type="primary" icon="el-icon-top">上传文件</el-button>
<!--              <div slot="tip" class="el-upload__tip">-->
<!--                只能上传jpg/png文件，且文件大小不超过500kb-->
<!--              </div>-->
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="btn">
          <button  @click="dialogVisible = false">取消</button>
          <button  @click="determine">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="withdraw">
      <el-dialog
        top="35vh"
        :visible.sync="handlelawsDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该文件，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="handlelawsDialog = false">取消</button>
          <button @click="submitLawDel">确定</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import reseacrh from "../../../api/research.js"
import {downloadFileId} from "@/utils/downloadUrl";
export default {
  data(){
    return{
      delDataItem: {}, //删除文件的数据
      handlelawsDialog: false, // 删除弹框
      fileName: "", // 文件名称
      Remarks:"", // 备注说明
      fileList:[], // 上传文件列表
      dialogVisible:false, //上传文件弹框
      multipleSelection: [],//多选框数据
      tableData: [],
      ppt:"ppt",
      imglist:[],
      num:-1,
      value:"",
      currentPage:1,
      PageCount:0,
      eachpage:10
    }
  },
  created(){
    this.filelist()
  },
  methods:{
    handleRemove(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList)
    },
    change(file,fileList) {
      this.fileList = fileList
      console.log(this.fileList)
    },
    determine(){ //确定文件上传
      if (this.fileList.length == 0) {
        this.$message.error('请上传文件')
      }
      this.$refs.newupload.submit();
    },
    // editRemove(file, fileList) { // 上传取消列表
    //   this.editFileList = fileList;
    //   console.log(this.fileLists)
    // },
    beforeUpload(file){ // 选择的文件上传
      let objUpload = {
        Id: this.$store.state.projectId,
        Type:1,
        Remarks: this.Remarks,
      };
      console.log(file);
      let fd = new FormData();
      fd.append("file", file);
      fd.append("data", JSON.stringify(objUpload));
      reseacrh.TopicUploadFile(fd).then((res) => {
        if (res.data.Status == 1) {
          this.dialogVisible = false;
          this.Remarks = "";
          this.fileName = ""
          this.filelist()
          // this.fileList = []
          this.$message.success(res.data.Message);
        }else{
          this.$message.error(res.data.Message)
        }
        console.log(res);
      });
      return true;
    },
    lawsadd(){
      var fileobj={
        TopicId:this.$store.state.projectId,//课题id
        Type: 1,
        PageIndex:0,
        PageSize:10,
      }
      reseacrh.lawslist(fileobj).then(res=>{
        if(res.data.Status==1){
          this.imglist=res.data.Entity
          console.log(res);
        }else{
          alert(res.data.Message)
        }
      })
    },
    add() { // 上传文件
      this.Remarks = ""
      this.dialogVisible=true
    },
    edit() { //编辑

    },
    handleSelectionChange(val) { //多选
      this.multipleSelection = val;
    },
    handleCurrentChange(val){
      this.currentPage=val
      this.filelist()
      console.log(`当前页: ${val}`);
    },
    pre(id){
      reseacrh.preview(id).then(res=>{
        console.log(res);
        if(res.data.Status==1){
          if(/\.(pdf|PDF|png|PNG|jpg|JPG)$/.test(res.data.ExtraData.Url)){
            window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
          }else{
            this.$message.warning("暂时只支持pdf文件预览")
            this.downloadfile(id)
            // window.open("https://view.officeapps.live.com/op/view.aspx?src=" + window.global_config.BASE_URL2+res.data.ExtraData.Url);
          }
        }
      })

    },
    filelist(){
      var fileobj={
        TopicId:this.$store.state.projectId,
        Type: 1,
        PageIndex:this.currentPage-1,
        PageSize:this.eachpage,
      }
      reseacrh.GetPagedTopicDataType(fileobj).then(res=>{
        if(res.data.Status==1){
          // this.imglist=res.data.Entity
          this.tableData = res.data.Entity
          this.PageCount=res.data.TotalRowsCount
          console.log(res);
        }else{
          this.$message.warning(res.data.Message)
        }
      })
    },
    submitLawDel() {
      reseacrh.alldelet({Id:this.delDataItem.Id}).then(res=>{
        if(res.data.Status==1){
          this.$message.success(res.data.Message)
          this.handlelawsDialog = false
          this.filelist()
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    deletefile(item){//删除文件
      this.handlelawsDialog = true
      this.delDataItem = item
    },
    downloadfile(id){
      downloadFileId(id)
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../../../styles/mixin.scss";
.lawsand-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .lawand-content{
    width: 96%;
    //height: 820px;
    height: 92%;
    //margin-left: 1%;
    margin-top: 30px;
    background: #FFFFFF;
    opacity: 1;
    padding: 1% 1% 0 1%;
    border-radius: 10px;
    //box-sizing: border-box;
    .lawand-header-upload{
      width: 100%;
      margin-bottom: 10px;
      /deep/.el-button {
        @include add-size($font_size_16);
      }
    }
  }
}
.lawsand-upload {
  /deep/.el-dialog {
    width: 510px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      margin-bottom: 20px;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .btn {
      margin-top: 52px;
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
.withdraw /deep/.el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    border-radius: 10px 10px 0px 0px;
    background: #3388ff;
    margin-bottom: 20px;
    .header-title {
      .title-name {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #ffffff;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 6px;
      }
      .title-age {
        vertical-align: top;
        display: inline-block;
        @include add-size($font_size_16);
        color: #ffffff;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
  .btn {
    margin-top: 52px;
    text-align: center;
    button:nth-child(1) {
      width: 180px;
      background: #ffffff;
      border: 1px solid #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #3388ff;
      margin-right: 20px;
    }
    button:nth-child(2) {
      width: 180px;
      //background: #ffffff;
      border: 1px solid #3388ff;
      background: #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #fff;
    }
  }
}
  .block {
    margin-top: 20px;
  }
  .content /deep/.el-table th {
    @include add-size($font_size_16);
    vertical-align: middle;
  }
  .content /deep/.el-table__row {
    @include add-size($font_size_16);
    vertical-align: middle;
  }
.content {
  ::-webkit-scrollbar {
    width:8px!important;
    height: 4px;

  }
  ::-webkit-scrollbar-thumb {
    //width: 20px;
    //height: 4px;
    background-color: #1890ff;
    border-radius: 10px;
  }
}
</style>
